<template>
  <div class='page plan'>
    <div class='allow'>
      <div class="plan_options text-left" :class="getTopBanner ? 'position_plan_options_plus' : 'position_plan_options'" v-show="!hidenSidebar">
        <div class="inner flex flex-col set-plan" :class='{active: !showDirections}' >
          <div class="" >
            <div class="top-controls flex items-center justify-between">
              <div class="flex items-center justify-between gap-10p">
                <div class="icon pointer" @click="hidenSidebar = true">
                  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M25 28L19 22L25 16" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <rect x="1" y="1" width="42" height="42" rx="7" stroke="#1FBC2F" stroke-width="2"/>
                  </svg>
                </div>
                <div class="title fw-b">
                  Plan options
                </div>
              </div>
              <div @click="resetAll" class="pointer btn_danger flex items-center justify-between">
                <div class="icon">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 9L15 15" stroke="#F2374D" stroke-width="2" stroke-linecap="round"/>
                    <path d="M15 9L9 15" stroke="#F2374D" stroke-width="2" stroke-linecap="round"/>
                    <circle cx="12" cy="12" r="9" stroke="#F2374D" stroke-width="2"/>
                  </svg>
                </div>
                <span>Reset all</span>
              </div>
            </div>
          </div>
          <PlanLocations
              @push-markers='pushMarkers'
              @set-locations-names='setLocationsNames'
              @reverse-points='reversePoints'
              :cached_markers='selected_points'
              :reset_all='reset_all'
              :selected_points_cloned='selected_points_cloned'
          />
          <PlanOptions
              :reset_all='reset_all'
              @push_options='pushOptions'
              :transport_type='transport_type.id'
              :route_options='saved_route_options'
          />
          <DateSelect
            @selectDates='selectDates'
            :reset_all='reset_all'
            :route_line='routeLine'
          />
          <PlanVehicles
              @selectVehicles='selectVehicles'
              :vehiclesDimensions='vehiclesDimensions'
              :reset_all='reset_all'
          />
          <PlanTrailers
              v-if='!hide_tailers'
              @openTrailersPopup='showTrailersPopup'
              :trailersDimensions='trailersDimensions'
          />
          <PlanDrivers
            @selectDriver='selectDriver'
          />
          <div class="actions generate w-full flex items-center">
            <button class="green-white w-full text-center flex items-center" @click="generatePlan">Generate</button>
          </div>
        </div>
        <div class='inner view-plan ' v-if='showDirections'>
          <SaveRoute
              @edit_route='editRoute'
              @share_route='shareRoute'
              @save_route='saveRouteV2'
              @choose_route='updateSelectedRouteIndex'
              :route_data='routeLine'
              :vehicles_dimensions='vehiclesDimensions'
          />
        </div>
      </div>
      <div v-show='hidenSidebar'>
        <div class="icon rotated" @click="hidenSidebar = false">
          <svg transform="rotate(180)" width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25 28L19 22L25 16" stroke="#1FBC2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <rect x="1" y="1" width="42" height="42" rx="7" stroke="#1FBC2F" stroke-width="2"/>
          </svg>
        </div>
      </div>
      <AltHeader class="alt_header"></AltHeader>
      <HereMap
          :reset_all='reset_all'
          :routeData='routeLine'
          :selected_points='selected_points'
          @update_points='pushMarkers'
          @selected_way='updateSelectedWayIndex'
          :is_edit='is_edit'
          :multi_points_center='multi_points_center'
          :selected_route_id='selected_route_id'
          :rote_replan='rote_replan'
      />

    </div>

    <div class="flex gap-15">
      <div class="add_items add_vehicles" v-if="openVehiclesPopUp"
           :class="{'w-1/3 minified': openTrailersPopup, 'w-2/3': !openTrailersPopup, }"
      >
        <PopupVehiclesSelect
            :reset_all='reset_all'
            @closeVehiclesPopup='closeVehiclesPopup'
            @setSelectedVehiclesDimensions='setSelectedVehiclesDimensions'
            :selected_vehicles_ids='selected_vehicles_ids'
        />
      </div>
      <div class="add_items add_trailers"
           :class="{'w-1/3 minified r-side': openVehiclesPopUp, 'w-2/3': !openVehiclesPopUp, }"
           v-if="openTrailersPopup">
        <PopupTrailersSelect
            :reset_all='reset_all'
            @closeTrailersPopup='closeTrailersPopup'
            @setSelectedTrailersDimensions='setSelectedTrailersDimensions'
            :selected_trailers_ids='selected_trailers_ids'
        />
      </div>
    </div>

    <SaveRoutePopup
        :open_route_name_popup="open_route_name_popup"
        @saveDirections="storeRouteV2"
    />
  </div>
</template>

<script>

import HereMap from "@/components/HereMap.vue";
import {baseErrHandler, generateRoute, getRouteById, storeRoute} from "@/services/api";
import {mapGetters, mapMutations} from "vuex";
import PlanLocations from "@/components/plan-elements/PlanLocations.vue";
import DateSelect from "@/components/plan-elements/DateSelect.vue";
import PlanVehicles from "@/components/plan-elements/PlanVehicles.vue";
import PopupVehiclesSelect from "@/components/PopupVehiclesSelect.vue";
import PlanTrailers from "@/components/plan-elements/PlanTrailers.vue";
import PopupTrailersSelect from "@/components/PopupTrailersSelect.vue";
import PlanDrivers from "@/components/plan-elements/PlanDrivers.vue";
import structuredClone from "@ungap/structured-clone";
import AltHeader from "@/components/AltHeader.vue";
import SaveRoute from "@/components/plan-elements/SaveRoute.vue";
import SaveRoutePopup from "@/components/plan-elements/SaveRoutePopup.vue";
import PlanOptions from "@/components/plan-elements/PlanOptions.vue";
import {infoMessage} from "@/services/messages";

export default {
  components: {
    PlanOptions,
    SaveRoutePopup,
    SaveRoute,
    AltHeader,
    PlanDrivers,
    PopupTrailersSelect,
    PlanTrailers,
    PopupVehiclesSelect,
    PlanVehicles,
    DateSelect,
    PlanLocations,
    HereMap
  },
  data() {
    const startDate = new Date();
    const endDate = new Date();
    return {
      hide_tailers: false,
      reset_all: false,
      pickerDates: {
        startDate: startDate,
        endDate: endDate,
        is_departure: true
      },
      locations_names: {
        start: null,
        end: null
      },
      transport_type: {
        id: 'straightTruck',
        name: 'Straight Truck'
      },
      saved_option_id: null,
      routeLine: null,
      showDirections: false,
      is_edit: false,
      open_route_name_popup: false,
      hidenSidebar: false,
      openVehiclesPopUp: false,
      openTrailersPopup: false,
      multi_points_center: false,
      rote_replan: false,
      selected_driver_id: null,
      selected_way_index: 0,
      selected_route_id: 0,
      selected_points_cloned: [],
      points: [],
      selected_points: [],
      vehiclesDimensions: {},
      vehiclesDimensionsClone: null,
      selectedVehicles: [],
      trailersDimensions: {},
      trailersDimensionsClone: null,
      selectedTrailers: [],
      selected_trailers_ids: [],
      selected_vehicles_ids: [],
      route_options: {
        toll_road: false,
        difficult_turns: false,
        tunnel: false,
        u_turns: false,
        motorway: false,
        mode: 'short',
        trafficData: false,
        excludedRoads: true
      },
      saved_route_options: null
    }
  },
  methods: {
    ...mapMutations([
      'setLoading',
    ]),
    resetAll() {
      this.reset_all = true;
      this.routeLine = null;
      this.showDirections = false;
      this.hidenSidebar = false;
      this.openVehiclesPopUp = false;
      this.openTrailersPopup = false;
      this.selected_driver_id = null;
      this.points = [];
      this.selected_points = [];
      this.vehiclesDimensions = this.vehiclesDimensionsClone;
      this.selectedVehicles = [];
      this.trailersDimensions = this.trailersDimensionsClone;
      this.selectedTrailers = [];
      setTimeout(() => {
        this.reset_all = false;
      }, 100);
    },
    addPoint() {

    },
    reversePoints() {
      this.selected_points = this.selected_points.reverse();
    },
    async updateSelectedWayIndex(val) {
      this.selected_way_index = val;
    },
    async updateSelectedRouteIndex(val) {
      this.selected_route_id = val;
    },
    async pushMarkers(markers) {
      await this.resetPoints();
      await this.setPoints(markers);
    },
    resetPoints() {
      this.selected_points = [];
    },
    setPoints(val) {
      this.selected_points = [];
      this.selected_points = val;
    },
    pushOptions(options) {
      this.route_options = options;
    },
    selectDates(val) {
      if (!val) return;
      const {pickerDates, is_departure} = val;
      const unix_start_date = new Date(pickerDates.startDate).getTime()
      const unix_end_date = new Date(pickerDates.endDate).getTime()
      this.pickerDates = {
        startDate: unix_start_date,
        endDate: unix_end_date,
        is_departure: is_departure
      };
    },
    selectVehicles() {
      this.openVehiclesPopUp = true;
    },
    setLocationsNames(val) {
      this.locations_names = val;
    },
    closeVehiclesPopup() {
      this.openVehiclesPopUp = false;
    },
    showTrailersPopup() {
      this.openTrailersPopup = true;
    },
    closeTrailersPopup() {
      this.openTrailersPopup = false;
    },
    selectDriver(val) {
      if (!val) return;
      this.selected_driver_id = val;
    },
    selectTransportType(val) {
      if (!val) return;
      this.transport_type = val;
      this.route_options.transport_type = val.id;
    },
    editRoute(val) {
      if (!val) return;
      this.is_edit = true;
      this.showDirections = false;
    },
    shareRoute() {
    },
    saveRouteV2(val) {
      if (!val) return;
      this.open_route_name_popup = true;
    },
    async storeRouteV2(val) {
      if(!val) return;
      const name = val;
      await baseErrHandler(async () => {
        const {selectedVehicles, selectedTrailers, routeLine, selected_driver_id} = this;
        if(this.selected_way_index) {
          routeLine.routes[0] = routeLine.routes[this.selected_way_index];
        }

        //remove all routes except first
        routeLine.routes = [routeLine.routes[0]];
        const {data} = await storeRoute(
            {
              name,
              vehicles_dimensions: this.vehiclesDimensions,
              trailers_dimensions: this.trailersDimensions,
              vehicle_info: selectedVehicles,
              direction_data: routeLine,
              driver_id: selected_driver_id,
              trailer_info: selectedTrailers,
              route_options: this.route_options,
              route_info: {
                locations_names: this.locations_names,
                time_end : this.pickerDates.endDate,
                time_start: this.pickerDates.startDate,
                locations: [
                  {
                    geo: {
                      region: routeLine.routes[0].sections[0].departure.place.address || null,
                      postcode: routeLine.routes[0].sections[0].departure.place.postalCode || null,
                      admin_district: null,
                    },
                    lat: routeLine.routes[0].sections[0].departure.place.originalLocation.lat,
                    lon: routeLine.routes[0].sections[0].departure.place.originalLocation.lng
                  }
                ],
                distance: this.partDistance,
              }
            });
        await this.$router.push({name: 'Routes'})
        if (data?.route_id) {
          this.setShareNewRouteId(data.route_id);
          // this.$router.push({name: 'SaveRoute', params: {id: data.route_id}})
        }
      });
    },
    setSelectedVehiclesDimensions(val) {
      const  {vDimensions, selectedVehicles} = val;
      this.vehiclesDimensions = vDimensions;
      this.selectedVehicles = selectedVehicles;
      if (val.selectedVehicles[0]?.vehicle_type) {
        this.transport_type = {
          id: val.selectedVehicles[0].vehicle_type
        }
      }
      val.selectedVehicles.forEach((vehicle) => {
        if ([
          "straightTruck",
          "tractor",
          "bus",
          "privateBus",
          "taxi",
          "car"
        ].includes(vehicle.transport_type)) {
          this.transport_type = {
            id: vehicle.transport_type
          }
        }
      });
      if ([
        "bus",
        "privateBus",
        "taxi",
        "car"
      ].includes(this.transport_type.id)) {
        this.hide_tailers = true;
      } else {
        this.hide_tailers = false;
      }
    },
    setSelectedTrailersDimensions(val) {
      const  {tDimensions, selectedTrailers} = val;
      this.trailersDimensions = tDimensions;
      this.selectedTrailers = selectedTrailers;
    },
    async generatePlan() {
      this.multi_points_center = false;
      this.rote_replan = true;
      await baseErrHandler(async () => {
        const {
            selected_points,
            vehiclesDimensions,
            trailersDimensions,
            pickerDates,
            route_options,
            transport_type
          } = this;
        const date = new Date(pickerDates.startDate || pickerDates.endDate);
        const time_zone_offset = date.getTimezoneOffset();
        if (transport_type) {
          this.route_options['transport_type'] = transport_type.id;
        }
        const {data} = await generateRoute(
            {
              time_zone_offset,
              selected_points,
              vehicles_dimensions: vehiclesDimensions,
              trailers_dimensions: trailersDimensions,
              pickerDates,
              route_options,
              transport_type: transport_type.id
            });
        if (data?.name === 'Error') {
          infoMessage(
              'Oops. Something went wrong...' + data?.message || '',
              'error'
          );
        }
        if (data?.routes) {
          this.closeVehiclesPopup();
          this.closeTrailersPopup();
          this.routeLine = data;
          this.showDirections = true;
          this.is_edit = false;
        }
      });
    },
    setData() {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.api_version === 'v1') {
        this.$router.push({name: 'Plan'})
      }
      this.vehiclesDimensions = {
        width: {
          min: 1,
          max: 6,
          val: 2.5,
          step: 0.1,
          name: 'Width',
          dimention: 'm'

        },
        height: {
          min: 1,
          max: 10,
          val: 4,
          step: 0.1,
          name: 'Height',
          dimention: 'm'

        },
        length: {
          min: 1,
          max: 50,
          val: 12,
          step: 1,
          name: 'Length',
          dimention: 'm'

        },
        weight: {
          min: 1,
          max: 50,
          val: 18,
          step: 1,
          name: 'Weight',
          dimention: 't'

        },
      };
      this.trailersDimensions = {
        width: {
          min: 0,
          max: 6,
          val: 0,
          step: 0.1,
          name: 'Width',
          dimention: 'm'

        },
        height: {
          min: 0,
          max: 10,
          val: 0,
          step: 0.1,
          name: 'Height',
          dimention: 'm'

        },
        length: {
          min: 0,
          max: 50,
          val: 0,
          step: 0.5,
          name: 'Length',
          dimention: 'm'
        },
      };
      this.vehiclesDimensionsClone = structuredClone(this.vehiclesDimensions)
      this.trailersDimensionsClone = structuredClone(this.trailersDimensions)
    },
    async setRePlanData() {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.api_version === 'v1') {
        this.$router.push({name: 'Plan'})
      }
      const replan_route_id = this.$route.query.replan_route_id;
      if (!replan_route_id) {
        this.$router.push({name: 'Routes'})
      }
      this.multi_points_center = true;
      this.selected_points = [];
      const route = await getRouteById(replan_route_id);
      if (route.data.direction_data?.routes[0]) {
        this.selected_points_cloned.push({
          point: {
            address: route.data.direction_data.routes[0].sections[0].departure.place.address,
            position: {
              lat: route.data.direction_data.routes[0].sections[0].departure.place.originalLocation.lat,
              lng: route.data.direction_data.routes[0].sections[0].departure.place.originalLocation.lng,
            }
          },
          origin_locations_open: false,
          points: null,
          showList: false,
          postcode: null
        });
        this.selected_points.push({
          lat: route.data.direction_data.routes[0].sections[0].departure.place.originalLocation.lat,
          lng: route.data.direction_data.routes[0].sections[0].departure.place.originalLocation.lng,
        })
        for await (const section of route.data.direction_data.routes[0].sections) {
          this.selected_points_cloned.push({
            point: {
              address: section.arrival.place.address,
              position: {
                lat: section.arrival.place.originalLocation.lat,
                lng: section.arrival.place.originalLocation.lng,
              }
            },
            origin_locations_open: false,
            points: null,
            showList: false,
            postcode: null
          });
          this.selected_points.push({
            lat: section.arrival.place.originalLocation.lat,
            lng: section.arrival.place.originalLocation.lng,
          })
        }
      }

      if(route?.data?.route_options) {
        this.saved_route_options = route?.data?.route_options;
        if (route?.data?.route_options?.transport_type) {
          this.saved_option_id = route?.data?.route_options?.transport_type;
          this.transport_type = {
            id: route?.data?.route_options?.transport_type,
            name: route?.data?.route_options?.transport_type
          }
        }
      }

      this.is_edit = true;
      this.showDirections = false;
      this.routeLine = route.data.direction_data;
      if (route.data.vehicle_info) {
        route.data.vehicle_info.forEach((vehicle) => {
          if (vehicle.id) {
            this.selected_vehicles_ids.push(vehicle.id);
          }
          this.vehiclesDimensions.width.val = vehicle.width;
          this.vehiclesDimensions.height.val = vehicle.height;
          this.vehiclesDimensions.length.val = vehicle.length;
          this.vehiclesDimensions.weight.val = vehicle.weight;
        });
      }
      if (route.data.trailer_info) {
        route.data.trailer_info.forEach((trailer) => {
          if (trailer.id) {
            this.selected_trailers_ids.push(trailer.id);
          }
          this.trailersDimensions.width.val = trailer.width;
          this.trailersDimensions.height.val = trailer.height;
          this.trailersDimensions.length.val = trailer.length;
        });
      }
    }
  },
  async mounted() {
    await this.setData()
    if (this.$route.name === 'HereRePlan') {
      await this.setRePlanData();
    }
  },
  computed: {
    ...mapGetters([
      'getTopBanner',
      'getDropState',
      'getAddVehicles',
      'getAddTrailers'
    ]),
  },
}
</script>

<style lang="scss" scoped>
.active {
  &.set-plan {
    display: flex;
  }
}
.set-plan {
  display: none;
}
.vue2leaflet-map {
  height: 877px;
}
.location-name {
  font-weight: bold;
  font-size: 14px;
  color: #292929;
}
.add_items {
  position: absolute;
  top: 180px;
  left: 400px;
  min-height: 150px;
  z-index: 400;
  background-color: #fff;
  border-radius: 5px;
  &.r-side{
    left: calc(35% + 400px);
  }
}
.not-allow-banner {
  padding-top: 150px;
  margin-bottom: 200px;
}
.page {
  position: relative;
  .white-green {
    cursor: pointer;
  }
  .drivers {
    .white-green {
      padding: 12px;
    }
  }
  .inner {
    &.way {
      max-height: 650px;
      max-width: 350px;
      min-width: 320px;
      overflow-y: auto;
    }
  }
  .vue-range-slider.slider-component {
    .slider {
      .slider-dot {
        background-color: #1fbc2f !important;
      }
    }
  }
  .vehicles {
    .actions {
      .white-green {
        text-align: center;
        margin: auto;
      }
    }
  }
  .vue-range-slider {
    width: 100%!important;
  }
  .alt_header {
    position: absolute;
    height: 50px;
    z-index: 401;
  }
  .outer {
    position: relative;
    .input_actions {
      position: absolute;
      right: 0;
      top: 10px;
      width: 60px;
    }
  }
  .plan_options {
    min-width: 355px;
    position: absolute;
    left: 15px;
    z-index: 401;
    border-radius: 6px;
    background-color: #ffffff;
    .actions {
      .white-green {
        padding-top: 0;
        padding-bottom: 0;
        min-height: 44px;
      }
    }
    &>.inner {
      padding: 15px;
      max-height: 85vh;
      overflow: scroll;
      &.view-plan {
        overflow: hidden;
      }
    }
  }
  .position_plan_options {
    top: 90px;
  }
  .position_plan_options_plus {
    top: 90px;
  }
}
.btn_danger {
  padding: 7px 10px;
}
.input-group {
  input {
    border: 1px solid #D0D5DA;
    box-sizing: border-box;
    border-radius: 18px;
  }
}
.plan_item {
  .expand {
    padding-right: 10px;
    .active {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .mb30 {
    .flex {
      width: 100%;
      .mr-2 {
        margin: 0;
        width: 100%;
      }
      .vue-daterange-picker {
        width: 100%;
      }
    }
  }
}
.actions {
  &.save {
    padding-top: 15px;
    button {
      min-width: 80px;
    }
  }
}
.preferences {
  input[type="radio"] {
    max-width: 20px;
    &:checked {
      &:checked {
        background: #FFA800 !important;
        border: #FFA800 !important;
      }
      &:before, &:after {
        background: #FFA800 !important;
        border: #FFA800 !important;
      }
    }
  }
}
.vue-daterange-picker ::v-deep .reportrange-text{
  border: 1px solid #D0D5DA;
  box-sizing: border-box;
  border-radius: 18px !important;
  width: 100%;
}
.data {
  padding-bottom: 20px;
  .icon {
    width: 20px;
  }
}
.icon {
  &.rotated {
    width: 44px;
    height: 44px;
    background: #fff;
    border-radius: 8px;
    left: 15px;
    position: absolute;
    top: 150px;
    z-index: 999;
  }
}
.add_name {
  position: absolute;
  max-width: 400px;
  left: 0;
  right: 0;
  top: 300px;
  margin: auto;
  z-index: 401;
  background: #fff;
}
.closeZone {
  transform: rotate(180deg);
  float: left;
}
.openZone {
  background: #fff;
  padding: 5px 5px;
  border-radius: 4px;
  width: 34px;
  border: 2px solid rgba(0,0,0,.2);
  background-clip: padding-box;
}
.custom-control {
  .controls {
    width: 230px;
    height: 150px;
    padding-left: 15px;
    background-color: #fff;
    img {
      max-width: 20px;
    }
  }
}
.no_visibility {
  opacity: 0;
}
.dropdown_block {
  width: 100%;
  padding: 0 10px;
  z-index: 405;
  background: #ffffff;
  border: 1px solid #D0D5DA;
  box-sizing: border-box;
  border-radius: 5px;
  position: absolute;
  top: 54px;
  left: 0;
}
.title {
  font-size: 14px;
}
.locations{
  &.absolute {
    background-color: #fff;
    width: 100%;
    z-index: 100;
  }
}
.down {
  .rotate {

  }
}
</style>
